import React, { Component } from 'react';
import Tune from '../crude.mp3';

class Audio extends Component {

  render() {
    return (
      <div style={{textAlign: 'right'}}>
        <audio controls preload="none">
            <source src={Tune} type="audio/mpeg" />
        </audio>
    </div>
    );
  }
}

export default Audio;
