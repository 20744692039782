import React, { Component } from 'react';
import Audio from './Components/Audio';
import './App.css';

class App extends Component {

  /*constructor(props) {
    super(props);
    this.state = { apiResponse: "" };
  }

  callAPI() {
    fetch("http://localhost:9000/testAPI")
    .then(res => res.text())
    .then(res => this.setState({ apiResponse: res }))
    .catch(err => err);
  }

  componentDidMount() {
    this.callAPI();
  }*/

  render() {
    return (
      <div className="App">
        <Audio />
      </div>
    );
  }
}

export default App;
